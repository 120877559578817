/* Provide sufficient contrast against white background */
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-navigations/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css";
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.customCheckbox {
    margin-top: 0.5rem;
}

.e-grid * {
    font-size: 12px !important;
}

.e-grid .e-rowcell.customcss {
    font-weight: 500;
    font-size: 14px !important;
}

.e-grid .e-headercell.customcss {
    font-weight: 500;
    font-size: 14px !important;
}

.e-grid .e-gridheader.customcss {
    font-weight: 500 !important;
}

.e-grid .e-headercell.e-headercelldiv.customcss {
    font-weight: 500 !important;
}

.e-grid .e-headercell.e-headercelldiv.e-headertext.customcss {
    font-weight: 500 !important;
}
body > iframe {
    display: none;
}