// Here you can add other styles
.updateHeightForLoginScreen {
    min-height: 80vh !important;
}

.footerforLogin {
    background-color: #0e1215;
    height: 60px !important;
    display: grid !important;
}

.termsConditions {
    color: white;
    font-size: 16px;
    text-decoration: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    cursor: pointer;
}

.footerLink {
    text-decoration: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    cursor: pointer;
}

.footerLink a {
    text-decoration: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    cursor: pointer;
}


.termsConditions:hover {
    color: #EE9100;
    text-decoration: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    cursor: pointer;
}

.footerLink:visited {
    text-decoration: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    cursor: pointer;
}

.termsConditionsHeader {
    background-color: #EE9100;
    color: white;
    font-size: 2.5rem;
    padding-left: 10%;
    font-weight: 500;
    height: 60px;    
    font-style:normal;
}

.logoLink {
    text-decoration: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    cursor: pointer;
}
.logoLink a{
    text-decoration: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    cursor: pointer;
}

.customBody {
    padding-left: 10%;
    padding-right: 10%;
}

.balanceAndRemainingHours{
    margin-bottom:0;
}

.customHeader {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 2%;
    color: #EE9100;
}

.customCheckbox {
    margin-top: 0.5rem;
}